nav {
  ul {
    li {
      &.active {
        background-color: rgba(0, 0, 0, .2);
        box-shadow: 0px -10px color('cyan', 'base') inset;
      }
    }
    a {
      transition: background-color .3s, box-shadow .3s;

      &:hover {
        box-shadow: 0px -10px color('cyan', 'base') inset;
        background-color: rgba(0, 0, 0, .1);
      }
    }
  }
}

.side-nav {
  a {
    border-bottom: 1px solid color('cyan', 'base');
  }
}

// custom styled sidebar
ul.side-nav.fixed li.logo {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 80px;

  &:hover {
    background-color: transparent;
  }
}

ul.side-nav.fixed {
  overflow: hidden;

  li {
    line-height: 44px;

    &.active {
      background-color: rgba(0, 0, 0, .05);
    }

    a {
      font-size: 14px;
      line-height: 44px;
      height: 44px;
    }
  }

  ul.collapsible-accordion {
    background-color: #FFF;
  }

  // Only show scrollbar on hover
  &:hover {
    overflow-y: auto;
  }
}