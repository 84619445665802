footer.page-footer {
  margin-top: 0;
  padding-top: 0;

  .footer-copyright {
    background-color: rgba(0, 0, 0, .3);
  }


  .footer-social {
    overflow: hidden;
    color: rgba(255, 255, 255, .8);
    background-color: rgba(0, 0, 0, .3);
    @extend .light;

    a{
      color: rgba(255, 255, 255, .8) !important;
    }
    .row {
      margin: 0;

      .button {
        transition: background-color .3s;
        padding: 10px 0;
      }

      .facebook, .twitter, .youtube, .gplus {
        background-color: transparent;
      }
      .facebook:hover {
        background-color: $facebook-color;
      }
      .twitter:hover {
        background-color: $twitter-color;
      }
      .youtube:hover {
        background-color: $youtube-color;
      }
      .gplus:hover {
        background-color: $google-plus-color;
      }
    }
  }
}