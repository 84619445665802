.slider {
  .left, .right {
    @extend .valign-wrapper;
    float: none;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4;
    i {
      font-size: 52px;
      padding: 15px;
      cursor: pointer;
    }
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  .slides {
    .caption {
      background-color: rgba(0, 0, 0, .6);
    }
  }
}