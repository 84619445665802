.pagination {
  padding: 5px;
  @extend .card-panel;
  background-color: color('blue-grey','darken-4');
  text-align: center;

  li {
    padding: 0;
    a {
      color: #fff;
      padding: 5px 10px;
      font-size: 14px;
    }

    &.active a {
      color: #fff;
    }

    &.active {
      background-color: $secondary-color;
    }

    &.disabled a {
      cursor: default;
      color: #999;
    }

    i {
      font-size: 1.4rem;
      vertical-align: sub;
    }
  }

  li.pages ul li {
    display: inline-block;
    float: none;
  }
}
