html {
  background-color: color('grey', 'lighten-3');
}

.row {
  &.no-margin {
    margin-bottom: 0;
  }
}

.parallax-container {
  height: 360px;
}

.z-depth-half {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
}

.article-text{

  .article-title{
    h4{
      margin-top: 0;

    }
  }
  .article-text-body{
    text-align: justify;
    font-size: 14px;
    h1{
      font-size: 28px;
    }
    h2{
      font-size: 24px;
    }
    h3{
      font-size: 18px;
    }
    h4{
      font-size: 16px;
    }
    h5{
      font-size: 14px;
    }
    h6{
      font-size: 12px;
    }
    p{
      margin: 10px 0;
    }
  }

}

.ad-article{
  h5{
    font-size: 20px;
  }
}

.video-container {
  iframe, object, embed {
    border: none;
  }
}