.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0,0,0,.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: $chip-bg-color;

  i.material-icons {
    cursor: pointer;
    float: right;
    font-size: 16px;
    line-height: 32px;
    padding-left: 8px;
  }
  i.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;
    line-height: 32px;
    padding-left: 8px;
  }

  a{
    color: #444 !important;
    text-transform: none !important;
  }
}
