
.card {

  @extend .z-depth-half;

  .card-image {
    .card-title {
      right: 0;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  .card-toolbar {
    padding: 0 10px;
    @extend .card-action;

  }

  .published-box {
    font-size: 12px;
    color: color('grey', 'base');
  }

  &.small {
    .card-title {
      right: 0;
      font-size: 16px;
      line-height: 24px;
    }
    .card-content {
      padding-top: 0;
    }
  }
}

.article-card {
  @extend .card;
  width: 100%;
  .article-category {
    display: inline-block;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .article-image {
    padding-right: 0;
  }
  .datetime {
    font-size: 12px;
    color: color('grey', 'base');
    text-align: right;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .col {
    padding-left: 10px;
  }
  img {
    vertical-align: middle;
  }
  div.card-social-button, a.card-social-button {
    margin: 0 !important;
    padding: 8px;
    font-size: 24px;

    &.facebook {
      transition: box-shadow .3s !important;
      i {
        color: $facebook-color;
        transition: color .3s;
      }
      &:hover {
        i {
          color: #ffffff;
        }
        box-shadow: 0 -60px $facebook-color inset;
      }
    }
    &.twitter {
      transition: box-shadow .3s !important;
      i {
        color: $twitter-color;
        transition: color .3s;
      }
      &:hover {
        i {
          color: #ffffff;
        }
        box-shadow: 0 -60px $twitter-color inset;
      }
    }
    &.gplus {
      transition: box-shadow .3s !important;
      i {
        color: $google-plus-color;
        transition: color .3s;
      }
      &:hover {
        i {
          color: #ffffff;
        }
        box-shadow: 0 -60px $google-plus-color inset;
      }
    }
    &.disqus {
      transition: box-shadow .3s !important;
      a {
        font-size: 22px;
      }
      a, i {
        color: $disqus-color;
        transition: color .3s;
      }
      &:hover {
        a, i {
          color: #ffffff;
        }
        box-shadow: 0 -60px $disqus-color inset;
      }
    }
  }
}

.related-article {
  .related-article-title {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    @extend .cyan;
    @extend .center-align;
    @extend .white-text;
    @extend .z-depth-half;
    p{
      margin: 10px;
    }
  }
  .related-article-text{
    p{
      margin: 5px 0;
    }
  }
}

.article-ad-data {
  .article-ad-data-title {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    @extend .orange;
    @extend .center-align;
    @extend .white-text;
    @extend .z-depth-half;
    p{
      margin: 10px;
    }
  }
  .article-ad-data-text{
    p{
      margin: 5px 0;
    }
  }
}